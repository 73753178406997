import React, {Component} from 'react';
import Countdown from 'react-countdown';

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (completed) {
	  	// Render a complete state
		return (
			<>
				<h2 className="mb-4">The THL NFTs public minting has started!</h2>
			 	<div className="row">
					<div className="form-group">
						<p><a href="/mint" className="btn btn-info py-3 px-4 ">MINT</a></p>
					</div>
				</div>
			</>
		)

	} else {
	  // Render a countdown
	  return (
			<>
				<h2 className="mb-4">Public minting of THL NFTs will start in</h2>
 				<div className="row">
					<div className="col-md-3 justify-content-center"> 
						<div className="form-group justify-content-center">
							<h1 className="countdown">{days}</h1>
							<h3 className="mb-4">Days</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown">{hours}</h1>
							<h3 className="mb-4">Hours</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown"> {minutes}</h1>
							<h3 className="mb-4">Minutes</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown">{seconds}</h1>
							<h3 className="mb-4">Seconds</h3>
						</div>
					</div>
				</div>

			</>
	  );
	}
  };

export class SectionCountdown extends Component {
    render() {
        return (
            <section className="ftco-section">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-center ftco-animate">
							<Countdown date={(new Date(this.props.DataCountdown)).getTime() } renderer={renderer}></Countdown>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionCountdown;