import React, {Component} from 'react';

class Estratto2  extends Component {
    render() {
        return (
            <section id={this.props.estrattoId} className="ftco-section  testimony-section img ftco-animate" style={this.props.estrattoStile}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row d-md-flex justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-left ftco-animate">
                            <h2 className="mb-4">{this.props.estrattoTitolo}</h2>
                            <p className="mb-5" dangerouslySetInnerHTML={{ __html: this.props.estrattoContenuto }}></p>
                            {this.props.estrattoButtonLink ? (
                                <>
                                    <br /> 
                                    <p><a href={this.props.estrattoButtonLink} className="btn btn-info py-3 px-4" target="_blank" rel="noreferrer noopener">{this.props.estrattoButton}</a></p>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Estratto2;