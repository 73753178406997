import React, {Component} from 'react';

import Book from './components/book';
import VideoBackground from './components/videobackground';
import Team from './components_nft/team';
import Progetto from './components_nft/progetto';
import Anteprima from './components/anteprima';
import BooktrailerModal from './components/booktrailermodal'
import Faq from './components_nft/faq'
import Starmap from './components_nft/starmap'
import Piedone from './components/piedone';
import SectionCountdown from './components_nft/countdown';
import Navbar from './components/navbar';
import Estratto2 from './components/estratto2';
import Utilita from './components/utilita';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';
import videoBackgroundImage from './images/toward-hemelslinn-sfondo-video-2.jpg';
import backgroundBookImage1 from './images/toward-hemelslinn-nft-x4-THL.jpg';
import backgroundBookImage2 from './images/toward-hemelslinn-price.jpg';
import backgroundBookImage3 from './images/toward-hemelslinn-token.jpg';
import backgroundBookImage4 from './images/toward-hemelslinn-ebook.jpg';
import backgroundTeamImage1 from './images/team/NFT-Avrekh-520.png';
import backgroundTeamImage2 from './images/team/NFT-Lilla-520.png';
import backgroundTeamImage3 from './images/team/NFT-Skyler-520.png';
import backgroundTeamImage4 from './images/team/NFT-Eliosfera-520.png';
import imgCarosello01 from './images/carosello/Carosello-01.jpg';
import imgCarosello02 from './images/carosello/Carosello-02.jpg';
import imgCarosello03 from './images/carosello/Carosello-03.jpg';
import imgCarosello04 from './images/carosello/Carosello-04.jpg';
import imgCarosello05 from './images/carosello/Carosello-05.jpg';
import imgCarosello06 from './images/carosello/Carosello-06.jpg';
import imgCarosello07 from './images/carosello/Carosello-07.jpg';
import imgCarosello08 from './images/carosello/Carosello-08.jpg';

import booktrailerModalImage from './images/Banner-Youtube-Toward-Hemelslinn-trailer-3.1-960x540.png';
import videoBackgroundLocal from './images/toward-hemelslinn-background-v.3.1.mp4';
import imgStarmap01 from './images/starmap/starmap-Burning.png';
import imgStarmap02 from './images/starmap/starmap-Magma.png';
import imgStarmap03 from './images/starmap/starmap-Fiery.png';
import imgStarmap04 from './images/starmap/starmap-Fractured-Red.png';
import imgStarmap05 from './images/starmap/starmap-Janartaw.png';
import imgStarmap06 from './images/starmap/starmap-Binares.png';
import imgStarmap07 from './images/starmap/starmap-Edomer.png';
import imgStarmap08 from './images/starmap/starmap-Luna-Rossa.png';
import imgStarmap09 from './images/starmap/starmap-Amartasile.png';
import imgStarmap10 from './images/starmap/starmap-Bess-Tuksnesis.png';
import imgStarmap11 from './images/starmap/starmap-Tasmin.png';
import imgStarmap12 from './images/starmap/starmap-Simar.png';
import imgStarmap13 from './images/starmap/starmap-Aquarius.png';
import imgStarmap14 from './images/starmap/starmap-Methanio.png';
import imgStarmap15 from './images/starmap/starmap-Azos.png';
import imgStarmap16 from './images/starmap/starmap-Phobes.png';
import imgStarmap17 from './images/starmap/starmap-Kron.png';
import imgStarmap18 from './images/starmap/starmap-Tamius.png';
import imgStarmap19 from './images/starmap/starmap-Dhulka.png';
//import imgStarmap19 from './images/starmap/starmap-Dhulka.png';
//import imgStarmap20 from './images/starmap/starmap-Anila.png';
//import imgStarmap21 from './images/starmap/starmap-Azure.png';
//import imgStarmap22 from './images/starmap/starmap-Gold.png';
//import imgStarmap23 from './images/starmap/starmap-Golden.png';
import backgroundEstrattoImage2 from './images/the-story-background.jpg';
import backgroundEstrattoImage3 from './images/join-our-discord.jpg';
import backgroundUtilityImg01 from './images/utility/utility-community-crew.jpg';
import backgroundUtilityImg02 from './images/utility/toward-hemelslinn-prixian.jpg';
import backgroundUtilityImg03 from './images/utility/crypto-trade.jpg';
import backgroundUtilityImg04 from './images/utility/utility-high-definition-art.jpg';
import backgroundUtilityImg05 from './images/utility/utility-networks.jpg';
import backgroundUtilityImg06 from './images/utility/utility-airdrops.jpg';
import backgroundUtilityImg07 from './images/utility/microphone-1261792_1920.jpg';
import backgroundUtilityImg08 from './images/utility/digital-literature.jpg';
import backgroundUtilityImg09 from './images/utility/utility-community-found.jpg';
//import backgroundUtilityImg10 from './images/utility/galaxias-thl-nft.jpg';
import backgroundUtilityImg11 from './images/utility/environmental-initiative.jpg';
import backgroundUtilityImg12 from './images/utility/merchandising-toward-hemelslinn-02.jpg';
import backgroundUtilityImg13 from './images/utility/mostra-01-banda.jpg';
import backgroundUtilityImg14 from './images/utility/launchpad.jpg';
import backgroundUtilityImg15 from './images/utility/erc721a.jpg';
import backgroundUtilityImg16 from './images/utility/Toward-Hemelslinn-Visual-Novel-Game.jpg';
import AsSeeOnImg01 from './images/NFTCalendar.jpeg';

const videoBackgroundStyle = {backgroundImage: `url(${videoBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '700px'};
const styleBook1 = {backgroundImage: `url(${backgroundBookImage1})`,};
const styleBook2 = {backgroundImage: `url(${backgroundBookImage2})`,};
const styleBook3 = {backgroundImage: `url(${backgroundBookImage3})`,};
const styleBook4 = {backgroundImage: `url(${backgroundBookImage4})`,};
const styleTeam1 = {backgroundImage: `url(${backgroundTeamImage1})`,};
const styleTeam2 = {backgroundImage: `url(${backgroundTeamImage2})`,};
const styleTeam3 = {backgroundImage: `url(${backgroundTeamImage3})`,};
const styleTeam4 = {backgroundImage: `url(${backgroundTeamImage4})`,};

const caroselloStyle = {display: 'block', width: 400, padding: 30,};
const EstrattoStyle2 = {backgroundImage: `url(${backgroundEstrattoImage2})`,};
const EstrattoStyle3 = {backgroundImage: `url(${backgroundEstrattoImage3})`,};

const UtilityStyle01 = {backgroundImage: `url(${backgroundUtilityImg01})`,};
const UtilityStyle02 = {backgroundImage: `url(${backgroundUtilityImg02})`,};
const UtilityStyle03 = {backgroundImage: `url(${backgroundUtilityImg03})`,};
const UtilityStyle04 = {backgroundImage: `url(${backgroundUtilityImg04})`,};
const UtilityStyle05 = {backgroundImage: `url(${backgroundUtilityImg05})`,};
const UtilityStyle06 = {backgroundImage: `url(${backgroundUtilityImg06})`,};
const UtilityStyle07 = {backgroundImage: `url(${backgroundUtilityImg07})`,};
const UtilityStyle08 = {backgroundImage: `url(${backgroundUtilityImg08})`,};
const UtilityStyle09 = {backgroundImage: `url(${backgroundUtilityImg09})`,};
//const UtilityStyle10 = {backgroundImage: `url(${backgroundUtilityImg10})`,};
const UtilityStyle11 = {backgroundImage: `url(${backgroundUtilityImg11})`,};
const UtilityStyle12 = {backgroundImage: `url(${backgroundUtilityImg12})`,};
const UtilityStyle13 = {backgroundImage: `url(${backgroundUtilityImg13})`,};
const UtilityStyle14 = {backgroundImage: `url(${backgroundUtilityImg14})`,};
const UtilityStyle15 = {backgroundImage: `url(${backgroundUtilityImg15})`,};
const UtilityStyle16 = {backgroundImage: `url(${backgroundUtilityImg16})`,};


const countdownAttivo = false; //da impostare per la produzione 
const dataCountdown = "2023-06-30 13:00 UTC" //da impostare per la produzione - Cambiare in countdown.js la descrizione per la prevendita e per il conio pubblico

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class App extends Component {
	state = {
		aBooks: [
			{id: 0, bookTitolo: "Collection", bookStile: styleBook1, bookDescrizione: '<p>NFT token quantity: <span>10,000</span></p><p>Traits number: <span>216</span></p><p>Traits Categories: <span>Firmament, Red Star, Blue Star, Yellow Star, Sun, Far Planet, Nebula, Moon, Planet, Delphís and Chapter</span></p>'},
			{id: 1, bookTitolo: "Current Price", bookStile: styleBook2, bookDescrizione: "<p>Presale: <span><b>Ξ 0.08</b> (ETH), plus gas fee (max 5 THL for wallet)</span></p><p>Sale: <span><b>Ξ 0.12</b> (ETH), plus gas fee (max 20 THL for wallet)</span></p>"},
			{id: 2, bookTitolo: "Smart Contract", bookStile: styleBook3, bookDescrizione: "<p>Blockchain: <span>Ethereum</span></p><p>Token: <span>ERC-721A</span></p><p>Mint: <span>Whitelist, Public, low gas</span></p><p>Smart Contract hash: <span>0x94f278e3A491A5b8E668e81d816AFa394c6d6647</span></p>"},
			{id: 3, bookTitolo: "EBook", bookStile: styleBook4, bookDescrizione: "<p>Format: <span>Epub, Kindle Azw3, Kindle Mobi, PDF</span></p><p>Protection: <span>Nothing</span></p><p>Accessibility: <span>Accessible</span></p><p>Cover: <span>Customized with NFT image</span></p>"}
		],
		aTeam: [
			{id: 0, stileTeam: styleTeam1, nomeTeam: "MAURIZIO", ruoloTeam: "Co-Founder", descrizioneTeam: "The author of the novel Toward Hemelslinn. Senior developer and longtime sysadmin. Passionate about DeFi and NFT, he is responsible for the smart contract of Toward Hemelslinn NFT. He created and generated the 10,000 NFT tokens. He also runs the website."},
			{id: 1, stileTeam: styleTeam2, nomeTeam: "LILLA", ruoloTeam: "Co-Founder", descrizioneTeam: "Our supporter. She helps in the artistic decision-making process and in the planning of activities on social networks."},
			{id: 2, stileTeam: styleTeam3, nomeTeam: "SKYLER", ruoloTeam: "Co-Founder", descrizioneTeam: "The very young artistic consultant for both NFT and website graphics.<br />He is also preparing a non-THL NFT collection. In the future it will be presented by our team."},
			{id: 3, stileTeam: styleTeam4, nomeTeam: "ELIOSFERA", ruoloTeam: "Co-Founder", descrizioneTeam: "The publishing house (with its entire team) financed the project and will publish all works related to it (ebooks, hard books, visual novel games, comic series, animated series, movies etc.)"},
		],
		aFaq: [
			{id: 16, domanda: "What is an NFT?", risposta: "An NFT stands for <i>Non Fungible Token</i> and is an elegant way of saying that it is a one-of-a-kind digital item that users can buy, own and trade. Some main functions of the NFT are to be digital art and have an attractive appearance. Some offer additional utilities such as exclusive access to websites or participation in an event, think of it as a rare work of art that can also act as a 'members' card. Toward Hemelslinn NFTs are many more."},
			{id: 0, domanda: "What is Toward Hemelslinn?", risposta: "Toward Hemelslinn is both a science fiction novel and an <b>ERC-721A</b> NFT (THL) Token collection on the Ethereum network. Each of the <b>10,000 THL</b> NFTs is carefully designed. Each NFT represents a unique star system, generated as the cover of the novel of the same name."},
			{id: 1, domanda: "How was Toward Hemelslinn created?", risposta: "The novel took a few years of writing and editing. Each THL NFT is assembled by an algorithm associating a variety of properties with different possibilities in the following categories: Firmament, Red Star, Blue Star, Yellow Star, Sun, Far Planet, Nebula, Moon, Planet, Delphís and Chapter. Each THL NFT has an associated personalized copy of the novel."},
			{id: 10, domanda: "Will there be rarities?", risposta: "Yes. Toward Hemelslinn NFTs will consist of a series of overlapping objects. We will use a system like <a href='https://rarity.tools' target='_blank' rel='noreferrer noopener'>Rarity Tools</a> to help you keep track of the rarity of your THLs."},
			{id: 11, domanda: "How can I get a THL NFT?", risposta: "THLs will be available for purchase on our website through an initial sale. Upon purchase, a randomly selected star system will be minted on the blockchain and delivered to your wallet and OpenSea account."},
			{id: 8, domanda: "What will the minting price be?", risposta: "The price to mint a TOWARD HEMELSLINN NFT token will be <b>Ξ 0.12</b> ETH (<b>Ξ 0.08</b> ETH for whitelists)."},
			{id: 17, domanda: "Is there a whitelist?", risposta: "Yup! The whitelist will be the reward for active and loyal members. For more details see the announcements on <a href='https://discord.gg/bWJ9nDthpA' target='_blank' rel='noreferrer noopener'> our Discord server</a>."},
			{id: 6, domanda: "When will the presale take place?", risposta: "The pre-sale has been postponed; it is not defined at this time. 48 hours before the public sale and will last 24 hours. For details on how to whitelist, subscribe to <a href='https://discord.gg/bWJ9nDthpA' target='_blank' rel='noreferrer noopener'> our Discord</a>."},
			{id: 5, domanda: "When will the public sale take place?", risposta: "The public sale has been postponed; it is not defined at this time. For more information, please constantly check our <a href='https://twitter.com/TowardHemelslin' target='_blank' rel='noreferrer noopener'>X (Twitter)</a> and a <a href='https://discord.gg/bWJ9nDthpA' target='_blank' rel='noreferrer noopener'>Discord</a> server. Two weeks before the presale, a countdown will appear on the site header."},
			{id: 4, domanda: "How are THL NFTs distributed?", risposta: "Each NFT in the THL NFT collection will be assigned an ID from the starting sequence calculated with this formula: <br /><b>(tokenId + StartingIndex)% 10000 -> Starting Sequence Index</b>.<br />At the end of the minting we will report the final starting index. We will then always refer to the NFT THLs using their ID. The NFTs provenance hashes of the images and metadata will be entered unalterably into the smart contract. This will ensure that no one, not even the development team, will be able to modify the images or metadata."},
			{id: 12, domanda: "How many THL can I purchase in one transaction?", risposta: "During the public sale there is a limit of <b>20 THL</b> (subject to change) that can be purchased per wallet. During presale the limit is <b>5 THL</b> for each wallet."},
			{id: 7, domanda: "How do I mint?", risposta: "You need to link your <b>MetaMask ETH wallet</b> to our site by clicking on the <b>MINT</b> button. For more information on the minting of NFTs, visit our <a href='/howtomint'>HOW TO MINT</a> page."},
			{id: 13, domanda: "When will my THL be revealed?", risposta: "All NFTs will be revealed as soon as all NFTs are minted, or at the latest within seven days of public minting."},
			{id: 2, domanda: "In which languages is the novel Toward Hemelslinn available?", risposta: "At the moment the novel is available in Italian. As the THL NFT minting campaign progresses, it will be translated into English by a specialized professional translator. Later it will be translated into one or more languages chosen by the THL Community (see the <a href='#starmap'>STARMAP 1.0</a>)."},
			{id: 14, domanda: "What can I do with my THL?", risposta: "You are free to do anything with it under a non-exclusive license. Later, when the 3D characters are minted, you can use them in the metaverse. For more information see the <a href='#starmap'>STARMAP 1.0</a> and the <a href='/termsandconditions'>TERMS & CONDITIONS</a> pages."},
			{id: 18, domanda: "How many THL will be reserved for marketing?", risposta: "There will be <b>260 THL</b> in total. The breakdown is 60 for the founding team and 200 for marketing and other business purposes."},
			{id: 9, domanda: "What is the blockchain and what is the smart contract address of Toward Hemelslinn NFT?", risposta: "The THL NFTs will be <b>ERC-721A</b> tokens on the <b>Ethereum blockchain</b>. This is the verified and published smart contract: <a href='https://etherscan.io/address/0x94f278e3A491A5b8E668e81d816AFa394c6d6647' target='_blank' rel='noreferrer noopener'><b>0x94f278e3A491A5b8E668e81d816AFa394c6d6647</b></a>."},
			{id: 15, domanda: "How can I contact the team?", risposta: "Connect to our <a href='https://discord.gg/bWJ9nDthpA' target='_blank' rel='noreferrer noopener'>Discord</a>. In the meantime, all direct communication will take place there or on <a href='https://twitter.com/TowardHemelslin' target='_blank' rel='noreferrer noopener'>X (Twitter)</a>."},
		],
		aStarmap: [
			{id: 0, immagine: imgStarmap01, stile: {color: "green"}, stile2: {textDecoration: "line-through"}, icona: "satellite-dish", titolo: "Pre-Alpha", descrizione: "Work begins on drawing the star systems and the hundreds of features that distinguish them."},
			{id: 1, immagine: imgStarmap02, stile: {color: "green"}, stile2: {textDecoration: "line-through"}, icona: "satellite", titolo: "Alpha", descrizione: "Development of the Toward Hemelslinn NFT website. Set up of Discord server and X (Twitter) page."},
			{id: 2, immagine: imgStarmap03, stile: {color: "green"}, stile2: {textDecoration: "line-through"}, icona: "fighter-jet", titolo: "Beta", descrizione: "Development of the Toward Hemelslinn Novel website. Development of the Toward Hemelslinn Ebooks website for NFT THL owners. Completion and testing of the Smart Contract."},
			{id: 3, immagine: imgStarmap04, stile: "", stile2: {color: "white"}, icona: "bullhorn", titolo: "Gamma", descrizione: "Marketing Campaign. Launch an influencer marketing campaign, referral program, and collaboration agreements with other collections. Enhanced social media strategy and community building on X (Twitter) and Discord."},
			{id: 4, immagine: imgStarmap05, stile: "", stile2: {color: "white"}, icona: "rocket", titolo: "Release Candidate", descrizione: "Presale of <b>THL</b> NFTs (Ethereum Blockchain - NFT ERC-721A). During the presale, which is reserved for <b>whitelisted fans</b>, the minting price will be <b>Ξ 0.08</b> (ETH). <b>THLs</b> can be minted through this website by connecting your MetaMask wallet. An <b>ebook</b> copy of the novel personalized with the NFT image on the cover (in Italian) will also be released free of charge for each <b>THL</b>. Hard copies with a uniform cover (in Italian) will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests."},
			{id: 5, immagine: imgStarmap06, stile: "", stile2: {color: "white"}, icona: "shuttle-space", titolo: "Start", descrizione: "Start of the official sales. Ethereum (NFT ERC-721A) Blockchain generation of <b>10,000</b> Toward Hemelslinn NFT (<b>THL</b>). It will be possible to mint them through this website at a base cost of <b>Ξ 0.12</b> (ETH). The works will have varying degrees of rarity with unique characteristics. For each coinage, a free e-book copy of the novel will be published, personalized with the NFT image on the cover (in Italian). Hard copies with a uniform cover (in Italian) will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests."},
			{id: 6, immagine: imgStarmap07, stile: "", stile2: {color: "white"}, icona: "book-atlas", titolo: "5%", descrizione: "The <b>novel</b> Toward Hemelslinn will be translated from Italian into English by a professional translator and published. Each <b>THL</b> will receive a <b>free e-book copy</b> of the novel, personalized with the NFT image on the cover. Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests. We will start working on an NFT gallery with properties/rarity filters."},
			{id: 7, immagine: imgStarmap08, stile: "", stile2: {color: "white"}, icona: "meteor", titolo: "7%", descrizione: "We will be publish the Toward Hemelslinn Collection on <b>Rarity Tools</b> and other Rarity sites. We will expand our Discord and social media management, which will include the Community Manager and Moderator package to bring our THL Community to all galaxies."},
			{id: 8, immagine: imgStarmap09, stile: "", stile2: {color: "white"}, icona: "sun", titolo: "10%", descrizione: "To strengthen the connection between generative art and literary art, an alternative NFT collection will be produced: <b>Toward Hemelslinn Novel</b> (<b>THLN</b>). Each NFT, in addition to the chapter title, displays one of the 3,400 paragraphs of the novel, in either Italian or English. For each <b>THL</b> owned, the owner will receive a free copy of the <b>THLN</b> collection (plus gas fee). We will publish the Toward Hemelslinn Novel Collection on <b>Rarity Tools</b> and other Rarity sites."},
			{id: 9, immagine: imgStarmap10, stile: "", stile2: {color: "white"}, icona: "earth-europe", titolo: "25%", descrizione: "Some of NFT's work from the <b>Toward Hemelslinn</b> and <b>Toward Hemelslinn Novel</b> collections will be <b>donated</b> to our early adopters and fans."},
			{id: 10, immagine: imgStarmap11, stile: "", stile2: {color: "white"}, icona: "book-atlas", titolo: "30%", descrizione: "The novel Toward Hemelslinn will be translated by a professional translator into one or more languages chosen by the THL Community fans. After publication, a personalized digital copy (<b>ebook</b>) will be available free of charge to the owners of each <b>THL</b>. Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests."},
			{id: 11, immagine: imgStarmap12, stile: "", stile2: {color: "white"}, icona: "user-astronaut", titolo: "40%", descrizione: "A new NFT collection is produced: <b>Toward Hemelslinn Human</b> (<b>THLH</b>), featuring all the characters from the novel Toward Hemelslinn. <b>THL</b> owners will be able to pre-purchase the new <b>THLHs</b> at 50% of the official mintage price. The collection will be posted immediately on <b>Rarity Tools</b> and other rarity sites."},
			{id: 12, immagine: imgStarmap13, stile: "", stile2: {color: "white"}, icona: "hand-holding-heart", titolo: "50%", descrizione: "A donation fund of <b>6 ETH</b> will be set up for a charity chosen with the help of the THL Community fans (forest maintenance? tree planting? social aid? animal welfare?)."},
			{id: 13, immagine: imgStarmap14, stile: "", stile2: {color: "white"}, icona: "earth-asia", titolo: "60%", descrizione: "Some NFT artwork from the <b>Toward Hemelslinn Human</b> collection will be <b>donated</b> to our early adopters and our fan base."},
			{id: 14, immagine: imgStarmap15, stile: "", stile2: {color: "white"}, icona: "shirt", titolo: "70%", descrizione: "An exclusive Toward Hemelslinn <b>merchandise</b> line will be launched. There will be hats, t-shirts and more!"},
			{id: 15, immagine: imgStarmap16, stile: "", stile2: {color: "white"}, icona: "newspaper", titolo: "80%", descrizione: "The author will write a <b>screenplay</b> for the novel Toward Hemelslinn, which will be translated into English. A <b>series of comics</b> based on the novel Toward Hemelslinn will be produced in Italian, English and in the other languages chosen by the THL Community. A <b>visual novel game</b> will also be published in the same languages. Each <b>THL</b> owner will receive <b>all digital copies</b> of <b>comics series</b> (ebook) and <b>visual novel game</b> free of charge. Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests."},
			{id: 16, immagine: imgStarmap17, stile: "", stile2: {color: "white"}, icona: "user-astronaut", titolo: "90%", descrizione: "We will develop the <b>THL-Human for Metaverse/3D</b>. Every owner of a <b>THL-Human</b> will have a <b>free THL-Human 3D</b> (plus gas fee). The future of this characters will be tracked by each one of <b>YOU</b>, <b>the THL Community</b>!"},
			{id: 17, immagine: imgStarmap18, stile: "", stile2: {color: "white"}, icona: "graduation-cap", titolo: "100%", descrizione: "Building the longevity of the <b>THL collections</b> for the benefit of the THL Community is our priority! A <b>THL Community Arts Grant Fund</b> of <b>10 ETH</b> will be launched. Create, design, develop, or build something that the THL Community will appreciate (e.g. additional utilities for <b>THL</b>, additional art, memes, short stories, etc.) and receive funding from the Toward Hemelslinn team. On top of that, <b>50% of the OpenSea fees</b> will go to the <b>THL Community Arts Grant Fund forever</b>!"},
			{id: 18, immagine: imgStarmap19, stile: "", stile2: {color: "white"}, icona: "infinity", titolo: "Beyond", descrizione: "Our primary goal is to have a quick and concise launch to complete everything proposed in this Starmap. Next, with the help of the THL Community, we will design the <b>Starmap 2.0</b>. The author will <b>write</b> the <b>sequel</b> to Toward Hemelslinn and every THL owner will receive a <b>free digital copy</b> (ebook). Our ambition is to turn Toward Hemelslinn into a <b>movie</b>, shot in live action or produced in animation with the characters of <b>THL-Human</b>. We wouldn't mind turning Toward Hemelslinn into a <b>video game</b>. It all depends on finding producers who want to work with us and the whole THL Community to realize out the projects. There will also be many other utilities."}
		],
		aCarosello: [
			{id: 0, immagine: imgCarosello01, altdesc: "Immagine 1", titleslide: "", textDesc: "", interval: "2000"},
			{id: 1, immagine: imgCarosello02, altdesc: "Immagine 2", titleslide: "", textDesc: "", interval: "2000"},
			{id: 2, immagine: imgCarosello03, altdesc: "Immagine 3", titleslide: "", textDesc: "", interval: "2000"},
			{id: 3, immagine: imgCarosello04, altdesc: "Immagine 4", titleslide: "", textDesc: "", interval: "2000"},
			{id: 4, immagine: imgCarosello05, altdesc: "Immagine 5", titleslide: "", textDesc: "", interval: "2000"},
			{id: 5, immagine: imgCarosello06, altdesc: "Immagine 6", titleslide: "", textDesc: "", interval: "2000"},
			{id: 6, immagine: imgCarosello07, altdesc: "Immagine 7", titleslide: "", textDesc: "", interval: "2000"},
			{id: 7, immagine: imgCarosello08, altdesc: "Immagine 8", titleslide: "", textDesc: "", interval: "2000"}
		],
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
			{id: 1, linkHref: "https://mint.towardhemelslinn.io", linkNome: "MINT"},
			{id: 2, linkHref: "https://novel.towardhemelslinn.io", linkNome: "NOVEL"},
			{id: 3, linkHref: "https://galaxias.towardhemelslinn.io", linkNome: "GALAXIAS"},
			{id: 4, linkHref: "https://ebooks.towardhemelslinn.io", linkNome: "EBOOKS"},
			{id: 5, linkHref: "/#story", linkNome: "STORY"},
			{id: 6, linkHref: "/#starmap", linkNome: "STARMAP"},
			{id: 7, linkHref: "/#utility", linkNome: "UTILITY"},
			{id: 8, linkHref: "/#faq", linkNome: "FAQ"},
			{id: 9, linkHref: "/#team", linkNome: "TEAM"},
			{id: 10, linkHref: "https://whitepaper.towardhemelslinn.io", linkNome: "WHITEPAPER"}
		],
		aUtilita: [
			{id: 0, utilityStyle: UtilityStyle09, utilityTitolo: "Community grant fund", utilityDescrizione: "Building the longevity of THL collections for the benefit of the THL Community is our priority! A THL Community Arts Grant Fund of 10 ETH will be launched. Create, design, develop, or build something the THL Community appreciates (e.g. extra utility for THL, additional art, memes, short stories, etc.) and receive funding from the Toward Hemelslinn team. On top of that, 50% of OpenSea fees will go into the THL Community Arts Grant Fund forever!"},
			{id: 1, utilityStyle: UtilityStyle14, utilityTitolo: "LaunchPad Projects", utilityDescrizione: "Several NFT collections will be published both free and heavily discounted for our collection owners. In addition, there will be collaborations with promising project teams with whitelisting for owners of our collections."},
			{id: 2, utilityStyle: UtilityStyle01, utilityTitolo: "You will be part of a THL Galaxias NFT Community!", utilityDescrizione: "When you join the citizens of the Toward Hemelslinn galaxy, by purchasing an NFT or simply joining our Discord, you instantly become part of our Galaxias THL Community. A community that aims to support the visual and literary arts in Web 3.0 and in the metaverse, coming to conquer the NFTs galaxy."},
			{id: 3, utilityStyle: UtilityStyle02, utilityTitolo: "Access the Himidhium distribution", utilityDescrizione: "When you are the owner of an NFT Toward Hemelslinn you will automatically access our exclusive Himidhium distribution program. By actively participating in the THL Community you will be rewarded. From time to time we will study new ways of rewarding and giving."},
			{id: 4, utilityStyle: UtilityStyle03, utilityTitolo: "You own all the commercial rights of your NFT", utilityDescrizione: "When you buy an NFT Toward Hemelslinn, the visual art is completely yours. You can print it, do merchandising, use it in advertising or in any of your shows! For the novel there are copyright restrictions, you do not have a publishing contract. it's yours as any other book you bought would be."},
			{id: 5, utilityStyle: UtilityStyle04, utilityTitolo: "View your art in high definition", utilityDescrizione: "All Toward Hemelslinn NFTs are available in high resolution, 4k (3.333x5.000px) PNG. This allows you to view or print your NFT in high quality."},
			{id: 6, utilityStyle: UtilityStyle05, utilityTitolo: "Expand your network", utilityDescrizione: "Interstellar communications exist in the Toward Hemelslinn novel. By joining the Tower Hemelslinn Community you will find yourself in an intergalactic THL Community of like-minded people, from all walks of life. You will be welcomed by people who love to meet newcomers. In Discord you will find several channels in which you will be comfortable."},
			{id: 7, utilityStyle: UtilityStyle06, utilityTitolo: "Receive Airdrops via the Exponder", utilityDescrizione: "The Exponder allows for massive teleportation of giveaways. As the owner of a Toward Hemelslinn NFT you will be able to participate in the distribution of Himidhium prizes. You may be gifted with other NFTs, hard copies of novels and comics, grants for your art, etc."},
			{id: 8, utilityStyle: UtilityStyle07, utilityTitolo: "You have a voice in the project", utilityDescrizione: "The Toward Hemelslinn team will take your opinion into account in the development of the project. For example, we will ask you to choose non-profit organizations to donate to, how to develop the metaverse project, which works of art to subsidize, and much more."},
			{id: 9, utilityStyle: UtilityStyle08, utilityTitolo: "You will have free digital literature", utilityDescrizione: "You will get free copies of the Toward Hemelslinn novel and the entire Toward Hemelslinn comics collection. Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests."},
			//{id: 10, utilityStyle: UtilityStyle10, utilityTitolo: "Launchpad: new NFTs from the THL Galaxy collections", utilityDescrizione: "All owners of Toward Hemelslinn NFT will have one NFT of each of the Toward Hemelslinn Galaxias (THLG), Toward Hemelslinn Novel (THLN) and Toward Hemelslinn Human 3D (THLH3D) collections free of charge (excluding gas fees). They will also be able to mint the Toward Hemelslinn Human collection at 50% of the public sale price. The launchpad will include many other initiatives and collaborations."},
			{id: 10, utilityStyle: UtilityStyle11, utilityTitolo: "Responsability initiatives", utilityDescrizione: "A donation fund of 6 ETH will be set up to a charity chosen with the help of the THL Community fans (Social aid, Humanitarian Aid, Animal protection. Tree planting programs, forest maintenance)."},
			{id: 11, utilityStyle: UtilityStyle12, utilityTitolo: "Merch Store", utilityDescrizione: "We plan to open a merchandising shop that will allow you to customize a wide variety of products with your NFT image. You can customize t-shirts, sweatshirts, jackets, sweaters, smartphone and tablet cases, backpacks, pillow cases and much more. Our intention is to also launch exclusive clothing models."},
			{id: 12, utilityStyle: UtilityStyle13, utilityTitolo: "Ranking: rarity.tools & C.", utilityDescrizione: "All the Toward Hemelslinn collections will be indexed on Rarity Tools, Rarity Sinper and other Rarity sites. We will also make our NFT with properties/rarity filters gallery available."},
			{id: 13, utilityStyle: UtilityStyle15, utilityTitolo: "ERC-721A gas saving", utilityDescrizione: "An implementation of IERC721 with significant gas savings for minting multiple NFTs in a single transaction. The Toward Hemelslinn NFT (THL) contract will allow multiple NFTs to be minted at essentially the same cost as the minting of a single NFT."},
			{id: 14, utilityStyle: UtilityStyle16, utilityTitolo: "Visual Novel Game", utilityDescrizione: "A Visual Novel Game based on the novel Toward Hemelslinn will be produced in various languages. The visual novel will use characters from the previously minted Toward Hemelslinn Human (THLH) collection. Every THL owner will receive the Visual Novel game free of charge."},
		],
	}

	
  	render() {
		return (

			<div className="page">
				{/*SEZIONE BARRA DI NAVIGAZIONE */}
				<Navbar 
					linkSito='https://www.eliosfera.it'
					logo={logo}
					deskLogo='The Eliosfera publishing house logo'
					aNavbarLinks = {this.state.aNavbarLinks}
				/>
				<div id="pagina">
					{/*SEZIONE VIDEOBACKGROUND */}
					<VideoBackground 
						backgroundStyle={videoBackgroundStyle}
						backgroundVideo={videoBackgroundLocal}
						titolo="TOWARD<br />HEMELSLINN<br />NFT"
						romanzo="an NFT collection based on a novel by"
						autore="MAURIZIO FERUGLIO"
						tagLine="An exciting adventure, a love beyond time."
						tagLine2=""
						buttonPrenota="JOIN OUR DISCORD"
						buttonLink='https://discord.gg/bWJ9nDthpA'
						countdown="false"
					/>


					{/*SEZIONE BOOK */}
					<section className="ftco-section ftco-no-pt ftco-featured-model">
						<div className="container-fluid">
							<div className="row">
								{this.state.aBooks.map(sectionbook => (
									<Book
									key={sectionbook.id}
									sectionbook={sectionbook} />
								))}
							</div>
						</div>
					</section>


					{/*SEZIONE COUNTDOWN */}
					{ countdownAttivo ?
						<>
						<SectionCountdown
							DataCountdown = {dataCountdown}
						/>
						</>
						: null
					}


					{/*SEZIONE THE PROJECT */}
					<Progetto
						aCarosello={this.state.aCarosello}
						caroselloStyle={caroselloStyle}
						titoloProgetto="THE PROJECT"
						descrizioneProgetto="
							<p>We have created an NFT collection that is different from any other out there. Our collection is a combination of pictorial-generative and literary art.</p>
							<p>The Toward Hemelslinn NFT (THL) collection consists of 10,000 works of art created by our team and registered in single <em>Non Fungible Token</em> NFTs (ERC-721A) with proof of ownership on the Ethereum blockchain.</p>
							<p>Each NFT THL is closely related to the science fiction adventure novel Toward Hemelslinn, written by Maurizio Feruglio. Each work represents a possible cover and features one of the 28 chapter titles of the novel at the bottom of the page. For each THL purchased you will receive a  personalized digital copy of the novel (ebook) with the data and the THL NFT cover.</p>
							<p>This is not the only project; there are many other utilities. To find out more, scroll through our <a href='#starmap'>STARMAP 1.0</a>  and <a href='#faq'>FAQs</a>.</p>
							<p>Join our adventure and have fun!</p>"
					/>
				

					{/*SEZIONE STARMAP */}
					<Starmap 
						titolo = "STARMAP 1.0"
						starmap = {this.state.aStarmap}
					/>


					{/* SECTION ESTRATTO 2 */}
					<Estratto2
						estrattoId="story"
						estrattoStile={EstrattoStyle2}
						estrattoTitolo="THE STORY"
						estrattoContenuto="In the distant future, humanity is spread across millions of planets under the aegis of Galaxias. Interplanetary travel is the prerogative of a small elite of galactic corporations that monopolize the mineral resources necessary for interstellar navigation. Humanity, relegated to colonized planets, lives in a period of obscurantism. The danger of despotic domination and the restrictions of freedom is ever more palpable.<br />
						To thwart the impending coup, the Delphís crew plans a revolution that will free humanity from the yoke of oppressors. A peaceful revolution that will allow space travel on a grand scale, allowing humanity the opportunity to express its full potential in the arts, sciences and exploration of the galaxies.<br />
						In the middle of the mission, the Delphís crew finds themselves near an unknown planet. The only hope of surviving is to land there. They will discover an ancient culture, the key to the past, present and future.<br />
						A wide-ranging space opera. A love beyond time. An exciting adventure with food for thought about freedom, friendship and diversity."
						estrattoButton="JOIN OUR DISCORD"
						estrattoButtonLink='https://discord.gg/bWJ9nDthpA'
					/>



					{/*SEZIONE UTILITY AND BENEFIT */}
					<section id="utility" className="ftco-section">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-10 ftco-animate">
								<div className="heading-section text-center mb-5">
									<h2>UTILITY & BENEFIT</h2>
									<p>Discover the benefits and utilities of Toward Hemelslinn NFT!</p>
								</div>
								</div>
							</div>
						</div>
						<div className="container-fluid px-4">
							<div className="row d-flex">
								{this.state.aUtilita.map(benefit => (
									<Utilita
									key={benefit.id}
									Sectionutilita={benefit} />
								))}
							</div>
						</div>
					</section>




					{/*SEZIONE ANTEPRIMA EBOOK */}
					<Anteprima
						anteprimaTitolo="Preview"
						anteprimaTagLine="If you want to read the <em> Toward Hemelslinn </em> preview, enter your name and email. You will receive in your inbox the link to download the PDF preview in Italian."
						anteprimaAction="https://verso-hemelslinn.us11.list-manage.com/subscribe/post?u=81158fe55b1b7e6f79918d667&amp;id=25ffa18bb3"
						anteprimaButton="PREVIEW DOWNLOAD"
						anteprimaConsenso="I agree to the treatment of my personal information."
						anteprimaPrivacy="Privacy Policy"
						anteprimaNomePlaceholder="Name"
						anteprimaEmailPlaceholder="Email address"
					/>


					{/*SEZIONE BOOKTRAILER */}
					<BooktrailerModal
						booktrailerModalId="trailer"
						booktrailerModalImage={booktrailerModalImage}
						booktrailerModalVideo="tGZWt1m5ycE"
						booktrailerModalTitolo="TRAILER"
						booktrailerModalTagLine="Watch the trailer and get involved in the adventure!"
						booktrailerModalAltImg="Toward Hemelslinn trailer banner"
					/>


					{/*SEZIONE EBOOK */}
					{/*<Ebook
						ebookStile={sectionEbookStyle}
						ebookTitle="TOWARD HEMELSLINN ebook"
						ebookTagLine="Read it also in the ebook on all devices!"
						ebookElenco='<li><span class="ion-ios-checkmark-circle mr-2"></span> <em>Accessible</em> for the blind and visually impaired, in epub3 format.</li>
						<li><span class="ion-ios-checkmark-circle mr-2"></span> Other formats available: Kindle and PDF.</li>
						<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it with a screen reader or a braille reader.</li>
						<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it with Kindle eReaders, Kobo and others.</li>
						<li><span class="ion-ios-checkmark-circle mr-2"></span> You can read it on a tablet, smartphone or PC.</li>'
						ebookInfoAccessibilita="To learn more about accessibility, read (in italian language):"
						ebookLinkAccessibilita="https://www.eliosfera.it/ebook-con-contenuti-accessibili-eliosfera-editrice"
						ebookTitleLink="Link to the article Ebook with accessible content."
						ebookDescAccessibilita="Ebook with accessible content"
						ebookCallAction="So what are you waiting for! Adventure awaits you digitally too!"
						ebookLink="https://www.eliosfera.it/newsletter-eliosfera-editrice"
						ebookButton="SUBSCRIBE"
					/> */}


					{/*SEZIONE FAQ */}
					<Faq
						faq = {this.state.aFaq}
					/>


					{/*SEZIONE TEAM */}
					<section id="team" className="ftco-section">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
									<h2 className="mb-4">Team</h2>
								</div>
							</div>
							<div className="row d-flex">
								{this.state.aTeam.map(sectionteam=> (
									<Team
										key={sectionteam.id}
										stileTeam={sectionteam.stileTeam}
										nomeTeam={sectionteam.nomeTeam}
										ruoloTeam={sectionteam.ruoloTeam}
										descrizioneTeam={sectionteam.descrizioneTeam}
									/>
								))}
							</div>
						</div>
					</section>

					{/* SECTION ESTRATTO 2 */}
					<Estratto2
						estrattoId="discord"
						estrattoStile={EstrattoStyle3}
						estrattoTitolo="JOIN OUR COMMUNITY"
						estrattoContenuto="Join the Discord THL Community and become a part of <em>Galaxias</em>. An exciting adventure awaits you!"
						estrattoButton="JOIN OUR DISCORD"
						estrattoButtonLink='https://discord.gg/bWJ9nDthpA'
					/>




					{/*SEZIONE PIEDONE */}
					<Piedone
						piedoneLinkHowToMint='/howtomint'
						piedoneLinkNFTsProvenance='/nftsprovenance'
						piedoneLinkEbooksProvenance='/ebooksprovenance'
						piedoneLinkWhitePaper='https://whitepaper.towardhemelslinn.io'
						piedoneLinkTermConditions='/termsandconditions'
						piedoneLinkPrivacy='/privacy'
						piedoneLinkSmartContracts='/smartcontracts'
						piedoneIframe=''
						piedoneTwitter='https://twitter.com/TowardHemelslin'
						piedoneInstagram=''
						piedoneFacebook=''
						piedoneDiscord='https://discord.gg/bWJ9nDthpA'
						piedonePinterest=''
						piedoneOpensea='https://opensea.io/collection/toward-hemelslinn-nft'
						piedoneYoutube='https://www.youtube.com/@TowardHemelslinnNFT'
						piedoneCopyright="Eliosfera Editrice &copy;"
						piedoneCopyrightDisclaimer="All rights reserved"
						piedoneCCBY='This template is built from a base of <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						piedoneAsSeenOn ={`<li className="ftco-animate"><a href="https://nftcalendar.io/b/ethereum/" target="_blank" rel="noreferrer noopener"><span><img width="150px" src="${AsSeeOnImg01}"></span></a></li>`}
				
				/>

				</div>
		
				{AddLibrary('js/jquery.min.js')}
				{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
				{AddLibrary('js/jquery.waypoints.min.js')}
				{AddLibrary('js/jquery.stellar.min.js')}
				{AddLibrary('js/owl.carousel.min.js')}
				{AddLibrary('js/jquery.magnific-popup.min.js')}
				{AddLibrary('js/aos.js')}
				{AddLibrary('js/jquery.animateNumber.min.js')}
				{AddLibrary('js/scrollax.min.js')}
				{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
				{AddLibrary('js/bootstrap-datepicker.js')}
				{AddLibrary('js/jquery.timepicker.min.js')}
				{AddLibrary('js/main.js')}
			</div>
    	);
	}
}

export default App;
