import React, {Component} from 'react';

class Team extends Component {
    render() {
		return (
			<div className="col-md-4 d-flex ftco-animate">
				<div className="blog-entry bg-dark align-self-stretch">
					<div className="block-30" title={this.props.deskLinkBlog}  style={this.props.stileTeam} >&nbsp;</div>
					<div className="text p-4 d-block">
						<div className="meta mb-3">
							<div>{this.props.ruoloTeam}</div>
						</div>
						<h3 className="heading mt-3"><a target="_blank" rel="noreferrer noopener" title={this.props.deskLinkBlog} href={this.props.linkPost}>{this.props.nomeTeam}</a></h3>
						<p dangerouslySetInnerHTML={{ __html: this.props.descrizioneTeam }}></p>
					</div>
				</div>
			</div>
        );
    }
}

export default Team;