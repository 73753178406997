import React, {Component} from 'react';

class SectionBook extends Component {
    render() {
        return (
            <div className="col-md-6 col-lg-3">
                <div className="model-entry">
                    <div className="model-img" style={this.props.sectionbook.bookStile}>
                        <div className="name text-center">
                            <h3>{this.props.sectionbook.bookTitolo}</h3>
                        </div>
                        <div className="text text-center">
                            <h3>{this.props.sectionbook.bookTitolo}</h3>
                            <div className="d-flex models-info">
                                <div className="box" dangerouslySetInnerHTML={{ __html: this.props.sectionbook.bookDescrizione }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//{{ backgroundImage:`url(${this.props.bookStile})` }}
export default SectionBook;