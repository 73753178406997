import React, {Component} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import '../css/faq.css';

class Faq extends Component {
    render() {
        return (
            <section id="faq" className="ftco-section bg-dark">
                <div className="container-fluid ftco-animate">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-center ftco-animate">
                            <h2 className="mb-0">FAQ</h2>
                         </div>
                         <div className="col-md-8 mb-4 heading-section ftco-animate">   
                            <Accordion allowZeroExpanded >
                                {this.props.faq.map((riga) => (
                                    <AccordionItem key={riga.id}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {riga.domanda}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel dangerouslySetInnerHTML={{ __html: riga.risposta }}></AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;