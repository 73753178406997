import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { IconName } from "@fortawesome/fontawesome-svg-core";
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import "../css/starmap2.css"

class Starmap extends Component {
    
    render() {
        return (
            <section id="starmap" className="ftco-section ">
                <div className="container-fluid ftco-animate">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-center ftco-animate">
                            <h2 className="mb-0">{this.props.titolo}</h2>
							<p>Note: The starmap may be subject to change.</p>
                        </div>
                        <div className="col-md-8 mb-5 heading-section ftco-animate"> 
                            <div className="row">
                                <div className="col-md-12" style={{ }}>
                                    <div className="main-timeline">
                                        {this.props.starmap.map((step) => (
                                            <div className="timeline ftco-animate" key={step.id}>
                                                <div className="timeline-content">
                                                    <div className="timeline-icon">
                                                        <FontAwesomeIcon icon={step.icona} style={step.stile}  />
                                                    </div>
                                                    <div className="timeline-year"><img className="timeline-year-img" src={step.immagine} alt="a"/></div>
                                                    <div className="inner-content">
                                                        <h3 className="title" style={step.stile2}>{step.titolo}</h3>
                                                        <p className="description" style={step.stile2} dangerouslySetInnerHTML={{ __html: step.descrizione }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Starmap;