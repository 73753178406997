import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import {Navbar, Nav} from 'react-bootstrap'

class BarraNavigazione extends Component {
    render() {
        return (
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
				<Container>
					<Navbar.Brand className="logo-azienda" href={this.props.linkSito}  target="_blank" rel="noreferrer noopener">
						<img height="70" src={this.props.logo} alt={this.props.deskLogo} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto"></Nav>
						<Nav>
							{this.props.aNavbarLinks.map((link) => (
								<Nav.Link key={link.id} href={link.linkHref}>{link.linkNome}</Nav.Link>
							))}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			
        );
    }

}

export default BarraNavigazione;


