import React, {Component} from 'react';
import Intestazione from './components_nft/intestazione';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/toward-hemelslinn-sfondo-2.jpg';


const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class TermsConditions extends Component {
	state = {
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
			{id: 1, linkHref: "https://mint.towardhemelslinn.io", linkNome: "MINT"},
			{id: 2, linkHref: "https://novel.towardhemelslinn.io", linkNome: "NOVEL"},
            {id: 3, linkHref: "https://galaxias.towardhemelslinn.io", linkNome: "GALAXIAS"},
			{id: 4, linkHref: "https://whitepaper.towardhemelslinn.io", linkNome: "WHITEPAPER"},
		],
	}
	render() {
		return (
			<>
				<div className="page">
					{/*SEZIONE BARRA DI NAVIGAZIONE */}
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='The Eliosfera publishing house logo'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>
					<div id="pagina">
						<Intestazione
							pagina="TERMS & CONDITIONS"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="TOWARD HEMELSLINN"
							romanzo="a novel by"
							autore="MAURIZIO FERUGLIO"
						/>


						<section className="ftco-section bg-dark">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>TERMS & CONDITIONS</h2>
											<p>Terms & Conditions</p>
										</div>
									</div>
								</div>
								<div className="row d-flex">
								
									<h2 className="text-center"><b>OBJECT AND DEFINITIONS</b></h2>
									<ol type="1">
										<li>These Terms and Conditions (the "Terms and Conditions") contain the terms and conditions that govern the access, provided by Eliosfera Editrice (Eliosfera s.a.s. di Maurizio Feruglio) having its registered office in Via Monte Baldo, 11/D - BEDIZZOLE BS - ITALY - VAT no. IT03687090989 (the "<b>Company</b>"), to users (the "<b>Users</b>") of the "Toward Hemelslinn" service (the "<b>Service</b>"), i.e. an exclusive club/community which reserves for holders of "Toward Hemelslinn NFT" (the "<b>THL</b>") "Non-Fungible Tokens" (the "<b>NFTs</b>") a series of utilities (the "<b>Utilities</b>"), as more fully described on the Company's platform (the "<b>Platform</b>") consisting of various websites, upon enrollment in the same by Users interested in obtaining the Utilities. <b>By proceeding with the use of the aforementioned Service, the User declares to accept the Terms and Conditions as described on the Platform, including the vexatious clauses specified in art. 49</b>.</li>
										<li>In addition to the terms defined elsewhere in the Terms and Conditions, for the purposes of the Terms and Conditions, the following terms are defined:
											<ol type="a">
												<li><b>Account</b>: means the User's personal profile on the Platform;</li>
												<li><b>Force Majeure</b>: by way of example and without any limitation, lockouts, strikes, wars, fires, epidemics, pandemics or any other event beyond the reasonable control of the Company and which prevents the full and proper fulfilment of the obligations set out in the General Conditions;</li>
												<li><b>Content</b>: includes, but is not limited to, any type of information, slides, text, photos, videos, graphics, music, audio and other material owned by the Company and/or any of the Company's licensors present on the Platform and/or part of the Service;</li>
												<li><b>Consumer Code</b>: means Legislative Decree no. 206 of 6 September 2005 on consumer rights;</li>
												<li><b>Consumer</b>: means the natural person who registers for purposes that are not related to their professional activity, as defined in the Consumer Code;</li>
												<li><b>Rights</b>: shall mean the intellectual property rights inherent to the Content, as well as trademarks, logos, domain names, designs, algorithms, software, and any other content that may be the subject of proprietary rights (including source codes) and/or any other intellectual property right related to the NFT, the Service and the Platform, owned exclusively and/or otherwise available to the Company;</li>
												<li><b>Registration</b>: procedure of filling in the form provided for the creation of the Account, as a necessary condition for using the Service.</li>
												<li><b>Art</b>: the art underlying the THL, unique image linked in blockchain to the specific THL downloadable in a graphic format (.jpg, .png etc).</li>
												<li><b>Attributes</b>: the individual objects that will be randomly combined to create each THL (e.g., Firmament, Blue Star, Delphís, Planet, and other material and/or similar attributes).</li>
												<li><b>Opera</b>: the novel "Toward Hemelslinn" written by Maurizio Feruglio and its transpositions (comics, animations, movies, plays, etc.).</li>
											</ol>
										</li>
									</ol>

									<h2 className="text-center"><br /><b>CONCLUSION OF TERMS AND CONDITIONS</b></h2>
									<ol type="1" start="3">
										<li>The User declares that he or she has read and expressly accepted the Terms and Conditions when using the Platform and/or registering for it by creating an Account and/or using the Service. The Terms and Conditions should be considered applicable, without distinction, to all Users who use all or part of the Service.</li>
										<li>If the User does not intend to accept even one of the terms and conditions of the Terms and Conditions, they are requested to refrain from using the Service and from registering to the Platform.</li>
										<li>The User acknowledges and accepts that the Company reserves the right to modify the Terms and Conditions at any time, at its own discretion, by publishing them on the Platform. A mere visit to the Platform following a change will be considered as full acceptance of the change by the User. <b>The User therefore acknowledges and accepts that it is their responsibility to periodically check the Platform for any changes to the Terms and Conditions</b>.</li>
									</ol>

									<h2 className="text-center"><br /><b>ENROLLMENT FINALIZATION AND SERVICES</b></h2>
									<ol type="1" start="6">
										<li>In order to use the Service it is necessary that:
											<ol type="a">
												<li>the User is the owner of one or more THLs, purchased through the Platform or through third parties indicated on the Platform, such as, but not limited to, the OpenSea.io platform, to whose terms and conditions the User is referred for further details;</li>
												<li>has correctly completed the Registration phase and created a personal Account. In order to proceed with Registration, the User must follow the instructions on the Platform, use the appropriate page provided by the Company and enter all the required data.</li>
											</ol>
										</li>
										<li>The User acknowledges and accepts that:
											<ol type="a">
												<li>it will be your responsibility, during the Registration phase, to indicate everything necessary to prove the ownership of the THL. The Company reserves the right to request additional information from you to prove ownership of the THL(s). In the absence of the requested information, the Company reserves the right not to accept the User's registration to the Platform;</li>
												<li>at no time during the aforementioned Registration procedure is the Company able to know the information relating to the identity of the User.</li>
											</ol>
										</li>
									</ol>
							
									<h2 className="text-center"><br /><b>UTILITY</b></h2>
									<ol type="1" start="8">
										<li>The Utilities, which are described in detail on the Platform, in the Starmap and in the WhitePaper, are made available to the User free of charge and are obtained by the User following Registration of the THL - at their discretion - on the Platform.</li>
										<li>Depending on the number of THLs owned by Users, the following minimum utilities made available to Users on the Platform will vary in quantity. The User acknowledges and agrees that the minimum Utilities made available to the User are as follows:
											<ol type="a">
												<li>For owners of No. 1 THL:
													<ol type="1">
														<li>Access to the channel "Hemelslinn Citizen" and following, present in the Discord Server "Toward Hemelslinn".</li>
														<li>For the THL owned will also be released free of charge an ebook copy of the novel (in Italian), personalized with the NFT image of the THL on the cover. Inside the ebook will be included the data related to the THL.</li>
														<li>Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests.</li>
														<li>The user will be able to coin an NFT "Toward Hemelslinn Galaxias" (THLG) for free, excluding gas fees.</li>
														<li>The user can coin a NFT "Toward Hemelslinn Novel" (THLN) free of charge, excluding gas fees.</li>
													</ol>
												</li>
												<li>For owners of a minimum of 3 THLs
													<ol type="1">
														<li>Access to the channel "Hemelslinn Citizen" and other related channels in the Discord Server "Toward Hemelslinn".</li>
														<li>Access to the channel "Delphís Crew" and other related channels, present in the Discord Server "Toward Hemelslinn".</li>
														<li>For each THL owned will be released free of charge an ebook copy of the novel (in Italian), personalized with the NFT image of the THL on the cover. Inside the ebook will be included the data related to the THL.</li>
														<li>Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests.</li>
														<li>The user, for each THL owned, will be able to coin a free NFT "Toward Hemelslinn Galaxias" (THLG), excluding gas fees.</li>
														<li>The user, for each THL owned, will be able to coin for free one NFT "Toward Hemelslinn Novel" (THLN), excluding gas fees.</li>
													</ol>
												</li>
												<li>For owners of a minimum of 10 THLs
													<ol type="1">
														<li>Access to the channel "Hemelslinn Citizen" and other related channels, present in the Discord Server "Toward Hemelslinn".</li>
														<li>Access to the channel "Delphís Crew" and other related channels, present in the Discord Server "Toward Hemelslinn".</li>
														<li>Access to the channel "First Guide" and other related channels in the Discord Server "Toward Hemelslinn".</li>
														<li>For each THL owned will be released free of charge an ebook copy of the novel (in Italian), personalized with the NFT image of the THL on the cover. Inside the ebook will be included the data related to the THL.</li>
														<li>Hard copies with a uniform cover will also be made available at a heavily discounted price. Several hard copies will be distributed free of charge during some airdrop contests.</li>
														<li>The user, for each THL owned, will be able to coin for free one NFT "Toward Hemelslinn Galaxias" (THLG), excluding gas fees.</li>
														<li>The user, for each THL owned, can coin for free a NFT "Toward Hemelslinn Novel" (THLN), excluding gas fees.</li>
													</ol>
												</li>
											</ol>
										</li>
										<li><b>Notwithstanding the foregoing with respect to the minimum Utilities, the Company reserves the right to add additional Utilities (the "Variable Utilities") that will vary over time in terms of quantity and/or type depending on the percentage of THL minted and at the sole discretion of the Company. In relation to the Variable Utilities, the User acknowledges and accepts that the Company reserves the right to modify at any time and at its own discretion the Variable Utilities that can be obtained by the User, informing the User, through publication on the Platform, of the duration and conditions of use of each single Variable Utility</b>. The mere visit to the Platform following the modification will be considered as full acceptance of the modification by the User. <b>The User acknowledges and accepts, therefore, that it will be the User's responsibility to periodically check the Platform in order to verify the presence of any changes to the Variable Utility, in relation to which the User declares as of now to waive any claim whatsoever, including reimbursement or compensation</b>. For further information the User may read the White Paper present on the Platform.</li>
										<li>With respect to the provisions of Article 9, the User acknowledges and accepts that the Utilities and Variable Utilities made available to the User may vary in terms of quantity and quality. Specifically, depending on the amount of THL owned, Users will have access to different types of Utilities described on the Platform, to which the User is referred for further details on the Utilities and Variable Utilities available depending on the package that the User can access by virtue of his or her NFT.</li>
									</ol>
		
									<h2 className="text-center"><br /><b>INTELLECTUAL PROPERTY RIGHTS</b></h2>
									<ol type="1" start="12">
										<li>The Company has created or otherwise obtained all intellectual property rights in various bases, features and attributes (each an "Attribute"), which will be randomly combined to create each THL (e.g., Firmament, Blue Star, Delphís, Planet and other material and/or similar attributes). No two THLs will include the same combination of attributes. The collection consists of 10,000 randomly generated and unique THLs that are registered on the Ethereum blockchain as ERC-721A tokens. Ownership includes all intellectual and commercial rights in the User-specific THLs.</li>
										<li>User acknowledges and agrees that it is the owner of the individual THL purchased, and that each THL is registered on the Ethereum blockchain. When the User purchases a THL, the User owns the underlying Art, outright. Ownership of the THL is mediated entirely by the Smart Contract and the Ethereum blockchain: at no time will the Company be able to seize, freeze, or otherwise change the ownership of any THL. You agree not to use the purchased THL in a manner that depicts or encourages illegal acts, or that is sexually explicit, defamatory, obscene, violent, hateful or libelous.</li>
										<li>You understand that one or more Attributes included in the THL you purchased will be included in one or more other THLs provided or sold to other Users. Accordingly, you cannot and do not receive any intellectual property rights in any individual Attributes included in the purchased THL, or in any combination of Attributes other than the specific, complete combination of all Attributes included in the purchased THL. The Company retains all intellectual property rights in each Attribute and in any combination thereof, except for the specific, complete combination of all Attributes in the purchased THL that is conveyed to You with these Terms and Conditions. </li>
										<li>Personal Use. Subject to your continued compliance with these Terms and Conditions, Company grants you a worldwide royalty-free license to use, copy and display the Purchased Art recorded in the THL, together with any extensions that you choose to create or use, solely for the following purposes:
											<ol type="a">
												<li>for personal, non-commercial use;</li>
												<li>as part of a marketplace that allows the purchase and sale of User's THL, provided that the marketplace cryptographically verifies each owner's rights to view the NFT for their Art to ensure that only the actual owner can view the Art;</li>
												<li>as part of a third-party website or application that allows for the inclusion, engagement or participation of User's THLs, provided that the website/application cryptographically verifies each NFT owner's rights to view the Art for their NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer viewable once the NFT owner exits the site.</li>
											</ol>
										</li>
										<li>Commercial Use. Subject to your continued compliance with these Terms and Conditions, Company grants you an unrestricted, worldwide license to use, copy and display the purchased Art for the purpose of creating derivative works based on the Art ("Commercial Use"). Examples of such Commercial Use would include, using the Art to produce and sell merchandising products (T-shirts, etc.) that display copies of the Art or using the Art as a sign for your business. For the sake of clarity, nothing in this Section will be deemed limiting to:
											<ol type="a">
												<li>owning or operating a marketplace that permits the use and sale of THLs generally, provided that the marketplace cryptographically verifies each owner's rights to display the Art for their NFT to ensure that only the actual owner can view the Art;</li>
												<li>own or operate a third-party website or application that allows for the inclusion, involvement or participation of THL in general, provided that the third-party website or application cryptographically verifies the rights of each THL owner to display the Art for its NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the purchased THL owner exits the site/application;</li>
												<li>gain from any of the foregoing.</li>
											</ol>
										</li>
										<li>THL IP (intellectual property). Apart from the rights to the Art, nothing herein gives the User any rights to other trademarks or other intellectual property rights belonging to the Company. All such rights are expressly reserved in the name of the Company. The Company shall always be free to use the Art worldwide for advertising and marketing purposes in any communication system it deems necessary, without having to pay any royalties.</li>
										<li>Feedback. You may choose to submit comments, bug reports, ideas or other feedback about the Platform, including, without limitation, how to improve the Platform (collectively, "Feedback"). By submitting any Feedback, You agree that Company is free to use such Feedback in any manner it chooses without any additional compensation to You and You hereby grant a perpetual, irrevocable, non-exclusive, worldwide license to incorporate and use the Feedback for any purpose.</li>
										<li>Company warrants the release of free digital copies (ebooks) of the Work for each minted THL. The ebook will have as cover and identification data those of the coined THL. In order to download the ebooks of the Work, the ownership of the relative THL must be cryptographically verified. The Work in the ebook will be subject to national and international copyright laws. All rights are reserved. Possession of the ebook does not give the user any rights to its content. The user must respect all copyrights due to the Company and the authors and not to copy, modify or resell the ebook.</li>
										<li>As indicated by the Starmap 1.0 (roadmap) in this Platform, the Company guarantees the publication of the comic book series based on the Work, only if the sales quota of THLs indicated in the Starmap 1.0 itself is reached. The ebooks of the comic book series based on the Work will be made available free of charge to each owner of the THLs. For the download of each ebook in the comic series, the ownership of the relevant THL must be cryptographically verified. The Work in the ebooks will be subject to national and international copyright laws. All rights are reserved. The possession of the ebook does not give any right to the user regarding the content of the same. The User must respect all copyrights belonging to the Company, the authors and must not copy, modify or resell the ebook.</li>
										<li>The Company DOES NOT guarantee in any way the release of an animated series, game or movie once the THL has been minted, nor does the Company promise any special value in the purchase of the THL product. The development of the game, animated series or movie depends on numerous factors, the Company does NOT promise or guarantee to Users that the characters in the eventual game, animated series or movie will resemble or have the same traits as Art. The eventual animated series, game or movie, referred to as a Work, will be subject to national and international copyright laws. All rights are reserved. The possession of the Work does not confer any right to the User regarding its content. The User must respect all copyrights belonging to the Company and the authors and must not copy, modify or resell the Work.</li>
										<li><b>In relation to the above, the User agrees to indemnify and hold harmless the Company from any damage or loss directly or indirectly resulting from the use that the User will make of all the object of the Rights in violation of the provisions of the Terms and Conditions</b>.</li>
									</ol>
			
									<h2 className="text-center"><br /><b>LIABILITY - LIMITATION OF LIABILITY IN FAVOUR OF THE COMPANY</b></h2>
									<ol type="1" start="23">
										<li>The User acknowledges and agrees that:
											<ol type="a">
												<li>the Company is limited to making the Service and the related Utilities available to the User and shall not be in any way liable to the User for any damages, losses, costs, charges and expenses, direct or indirect, including any legal fees, suffered and/or incurred by the User in connection with the purchase of the NFTs connected to the Service; </li>
												<li>renounces as of now any claim, even by way of reimbursement or compensation, against the Company.</li>
											</ol>
										</li>
										<li>Without prejudice to cases of fraud or gross negligence, <b>the User</b> i) <b>acknowledges and accepts that the Company shall not be liable in any way toward the User for any damages, losses, costs, charges and expenses, direct or indirect, including any legal fees, suffered and/or incurred by the User in connection with the Service and</b> ii) <b>hereby waives any claim, including reimbursement or compensation, against the Company</b>. Without prejudice to the foregoing, the Company shall not be liable in any way toward the User for any delays or failures to meet its obligations in relation to the Service in the event that such delays or failures are due to Force Majeure.</li>
										<li>The Company is not necessarily affiliated and/or partnered with the natural and/or legal persons who own the websites to which there are links within the Platform and/or the Service, and is not responsible in any way for the content of these websites. These links are provided solely for the convenience of Users and access to these websites is at the discretion and exclusive responsibility of Users. A link from the Platform or Service to any other website does not imply that the Company in any way approves, endorses or recommends such website or has any control over any element of the content of such website.</li>
									</ol>

									<h2 className="text-center"><br /><b>INDEMNITY</b></h2>
									<ol type="1" start="26">
										<li>The User agrees to indemnify and hold harmless the Company, its subsidiaries and affiliates, as well as their respective representatives, partners and employees from any damage, liability, cost, charge or expense, including legal fees, arising from claims by third parties as a result of the User's use of the Service in a manner that does not comply with the Terms and Conditions and/or violations of the provisions thereof, including the representations and warranties set forth in the relevant paragraph of the Terms and Conditions.</li>
									</ol>
			
									<h2 className="text-center"><br /><b>PURCHASE AND LEGAL AGE</b></h2>
									<ol type="1" start="27">
										<li>The Platform is not intended for children. You must be at least 18 years old or of legal age within your jurisdiction to access the Platform or purchase a THL. If you are under 18 years of age or not of legal age within your jurisdiction, you are not permitted to use the Platform for any purpose. By accessing the Platform, you represent and warrant that you are at least 18 years of age or the legal age within your jurisdiction.</li>
									</ol>		 
		
									<h2 className="text-center"><br /><b>FAILED TRANSACTIONS AND BUGS</b></h2>
									<ol type="1" start="28">
										<li>Limitations of Liability for Gas, Failed Transactions and Other Bugs. The Company worked very hard to ensure that there were no bugs in the Smart Contract and that the initial launch of THL could be as smooth as possible. As seen in many others' NFT projects, however, sometimes systems have glitches. You agree not to hold the Company liable for any losses you may incur as a result of the minting of the THL. These potential losses include any gas fees for failed transactions and any excessive gas fees during the minting process. You agree to waive any and all Class Actions and legal disputes relating to the THL Project.</li>
									</ol>	

									<h2 className="text-center"><br /><b>USER REPRESENTATIONS AND WARRANTIES</b></h2>
									<ol type="1" start="29">
										<li>The User acknowledges and expressly agrees that, in the event that the User violates the law, third party rights or the Terms and Conditions, the Company may - at its complete discretion - <b>suspend or interrupt the use of the Service for the User, prevent the User from accessing the Platform and/or the Account and/or any other website that the Company uses for the provision of the Service</b>, as well as take any other action necessary to protect the rights and interests of the Company.</li>
										<li>You are solely responsible for your conduct while accessing or using the Platform and for the consequences thereof. You agree to use the Platform only for lawful, appropriate purposes and in accordance with these Terms and Conditions and any applicable laws or regulations. By way of example and not limitation, You may not and shall not allow any third party to:
											<ol type="a">
												<li>post, upload, distribute or disseminate any unlawful, defamatory, harassing, offensive, fraudulent, hateful, violent, obscene or other objectionable content;</li>
												<li>distribute viruses, worms, spyware, bugs, Trojan horses, corrupted files, hoaxes or anything else of a destructive or deceptive nature;</li>
												<li>impersonate another person;</li>
												<li>upload, post, transmit or otherwise make available through the Platform any content that infringes the intellectual property or proprietary rights of any party or otherwise violates the legal rights of others;</li>
												<li>engage in, promote or encourage illegal activities (including, without limitation, money laundering);</li>
												<li>interfere with the use of the Platform by other users;</li>
												<li>use the Platform for any unauthorized commercial purpose;</li>
												<li>modify, adapt, translate or reverse engineer any part of the Platform;</li>
												<li>remove any copyright, trademark, or other proprietary rights notice contained in or on the Platform or any portion thereof;</li>
												<li>use any technology to collect information on the Platform for unauthorized purposes;</li>
												<li>access or use the Platform for the purpose of creating a product or service that is competitive with any of our products or services;</li>
												<li>if you engage in any of the activities prohibited by this Section, Company, in its sole and absolute discretion, without notice and without limiting any of its other rights or remedies at law or in equity, may immediately suspend or terminate your account.</li>
											</ol>
										</li>
									</ol>			
				
									<h2 className="text-center"><br /><b>FEES AND PAYMENTS</b></h2>
									<ol type="1" start="31">
										<li>If you choose to purchase a THL through the Platform, all financial transactions in which you engage will be conducted exclusively through the Ethereum blochain. The Company will have no view or control over these payments or transactions, nor will the Company have the ability to cancel any transaction. The Company shall have no liability to the User or any third party for any claims or damages that may arise as a result of any transaction that the User undertakes or any other transaction that the User will conduct through the Ethereum blochain.</li>
										<li>The Ethereum blockchain requires the payment of a transaction fee (a "Gas Fee") for each transaction that occurs on the Ethereum network. The Gas Fee funds the computer network that operates the Ethereum decentralized network. This means that the User must pay a Gas Fee for each transaction.</li>
									</ol>

									<h2 className="text-center"><br /><b>EXCLUSION OF WARRANTIES</b></h2>
									<ol type="1" start="33">
										<li>User accepts the THL purchased "as is", without any representation or warranty of any kind, express or implied, in excess of any legal warranty rights it may have under applicable law.</li>
									</ol>

									<h2 className="text-center"><br /><b>ASSUMPTION OF RISK</b></h2>
									<ol type="1" start="34">
										<li>You agree to the following:
											<ol type="a">
												<li>To the extent that there is a price or market for a blockchain asset, such markets and prices are extremely volatile and changes in the price of other digital assets could materially and adversely affect the value of any digital assets, including the GST that You have purchased, and there is no guarantee that the GST that You have purchased will have or retain any value.</li>
												<li>There are risks associated with the use of Internet-native assets (e.g., non-fungible tokens, cryptocurrencies, etc.) including, without limitation, the risk of hardware, software, and Internet connections and/or failures, the risk of the introduction of malicious software, and the risk that third parties may gain unauthorized access to information stored within the User's digital "wallet." The Company shall not be liable for any of these damages.</li>
												<li>The Company makes no promises or guarantees about the availability of the Art on the Internet or that it will host the Art in any specific location and/or for any specific period of time.</li>
												<li>Upgrades to the Ethereum platform, a hard fork on the Ethereum platform, a failure or termination of Ethereum, or a change in the way transactions are confirmed on the Ethereum platform may have unintended adverse effects on all blockchains utilizing such technologies, including, without limitation, purchased THLs.</li>
												<li>THLs are made available for entertainment purposes only.</li>
												<li>The Company is not responsible for any transaction between you and a third party (e.g., the purchase of a THL from a third party on the so-called "secondary market") or for any consequences of such transaction (e.g., any fees or taxes due in connection with such transaction) and the Company shall have no liability with respect to such transaction or its consequences.</li>
												<li>Ownership of a THL confers ownership of digital artwork only. Accordingly, no information on the Platform (or any other document referred to therein) is or may be deemed to be advice or an invitation to enter into an agreement for any investment purpose. In addition, nothing on this Platform qualifies or is intended as an offer of securities in any jurisdiction nor does it constitute an offer or invitation to purchase stock, securities or other financial products. Due to the artistic nature of the project, Toward Hemelslinn NFT (THL) has not been registered or approved by any regulatory authority in any jurisdiction. It remains your sole responsibility to ensure that your purchase of the THL and associated Art complies with the laws and regulations of your jurisdiction.</li>
												<li>NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unstable. New regulations could negatively impact these technologies, affecting the value of the NFTs purchased by the User. The User understands and accepts all risks in this regard.</li>
											</ol>
										</li>
									</ol>


									<h2 className="text-center"><br /><b>PRIVACY</b></h2>
									<ol type="1" start="35">
										<li><b>Obligations of the Company toward Users</b>. In the performance of its contractual obligations, the Company undertakes to comply with the provisions of Legislative Decree 196/2003, integrated with the amendments provided by Legislative Decree 101/2018 (hereinafter, the "<b>Code</b>") and EU Reg. 2016/679 (hereinafter, the "<b>Regulations</b>"; the Code and the Regulations together, the "<b>Privacy Policy</b>") and, to this end, undertakes to adopt any physical, logistical and organizational security measures necessary and appropriate to ensure respect for the integrity and accuracy of the personal data processed, as well as the lawfulness of their processing.</li>
										<li>The personal data of the Users are processed using manual and computerised tools, with logic strictly related to the processing purposes described in the Privacy Policy and, in any case, in such a way as to guarantee the security and confidentiality of the data. The User accepts that the Company has the right to access, store, transmit, use and process all the information that they provide in accordance with the terms of the Privacy Policy.</li>
										<li>In order to better know and understand how the Company uses the personal data of the Users, the User is invited to consult the Privacy Policy.</li>
									</ol>

									<h2 className="text-center"><br /><b>TRANSFER</b></h2>
									<ol type="1" start="38">
										<li>The User may not assign the Terms and Conditions in whole or in part to any third party.</li>
									</ol>

									<h2 className="text-center"><br /><b>DURATION AND SURVIVAL OF CLAUSES</b></h2>
									<ol type="1" start="38">
										<li>The Terms and Conditions are effective as of the date of acceptance of the Terms and Conditions, and will remain in effect for the duration of Users' use of the Service, unless terminated earlier as described in the Terms and Conditions and as provided below.</li>
										<li>The following provisions of the Terms and Conditions shall survive termination of the Terms and Conditions: User Representations and Warranties; Liability - Limitation of Liability in Favor of the Company; Applicable Law and Jurisdiction; General Clauses.</li>
									</ol>					

									<h2 className="text-center"><br /><b>TERMINATION</b></h2>
									<ol type="1" start="41">
										<li>The Company, pursuant to art. 1456 c.c., may terminate the Terms and Conditions with immediate effect, by simply sending written notice to the User also by e-mail, if the User has violated one or more of the following provisions of the Terms and Conditions: Finalization of Enrollment and Services; Utility; Assignment. The Company's right to compensation for damages remains unaffected.</li>
									</ol>

									<h2 className="text-center"><br /><b>APPLICABLE LAW AND JURISDICTION</b></h2>
									<ol type="1" start="42">
										<li>The Terms and Conditions are governed entirely by Italian law.</li>
										<li>Without prejudice to what is established for the protection of Consumers on the subject of competent court, any dispute arising between the Parties in relation to the validity, interpretation, execution and termination of the Terms and Conditions and/or in any case in connection with the Terms and Conditions will be the exclusive competence of the Court of Latina, with the exclusion of any other, even concurrent or alternative.</li>
										<li><b>ODR Platform</b>. Pursuant to European Regulation 524/2013, the Company informs the User that in case of dispute, he/she may submit a complaint through the ODR platform (Online Dispute Resolution) made available by the European Commission and accessible at the following link <a href="https://ec.europa.eu/consumers/odr/" alt="Link to Online Dispute Resolution" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>".</li>
									</ol>

									<h2 className="text-center"><br /><b>GENERAL CLAUSES</b></h2>
									<ol type="1" start="45">
										<li>Any forbearance on the part of the Company in respect of conduct by the User in breach of any provision of the Terms and Conditions shall not constitute a waiver of the rights arising out of the breached provision, nor of the right to require proper performance of all provisions of the Terms and Conditions.</li>
										<li>The Terms and Conditions contain the entire agreement reached by the Parties with respect to the subject matter of the Terms and Conditions and supersede all prior communications, representations, understandings and agreements, whether oral or written, reached by the Parties.</li>
										<li>If any term or other provision of the Terms and Conditions is declared void, voidable or unenforceable, all other terms and provisions of the Terms and Conditions shall nevertheless remain in full force and effect.</li>
										<li>The Company and the Users act autonomously and independently. The Terms and Conditions do not create between them any relationship of collaboration, agency, association, intermediation or employment.</li>
										<li><b>The User acknowledges and accepts that the following paragraphs of the Terms and Conditions contain some vexatious provisions, including the points listed below, and therefore, must expressly accept them when registering, pursuant to Articles 1341 and 1342 of the Civil Code</b>: Conclusion of the Terms and Conditions, point 5; Finalization of the registration and Services, point 7; The Utilities, point 8; Intellectual Property Rights, point 22; Liability - Limitation of Liability in Favor of the Company, points 23, 24 and 25; Indemnity, point 26; Declarations and Guarantees of the User, point 29; Survival of the Clauses, points 39 and 40; Applicable Law and Competent Court, points 42 and 43. The User is invited to carefully read the aforementioned clauses, in particular the points indicated (also in bold in the text) before using any of the Services, waiving any claim in relation to the same, even by way of reimbursement or compensation.</li>
									</ol>

								</div>
							</div>
						</section>

           
						<Piedone
							piedoneLinkHowToMint='/howtomint'
							piedoneLinkNFTsProvenance='/nftsprovenance'
							piedoneLinkEbooksProvenance='/ebooksprovenance'
							piedoneLinkWhitePaper='https://whitepaper.towardhemelslinn.io'
							piedoneLinkTermConditions='/termsandconditions'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts='/smartcontracts'
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/TowardHemelslin'
							piedoneInstagram=''
							piedoneFacebook=''
							piedoneDiscord='https://discord.gg/bWJ9nDthpA'
							piedonePinterest=''
							piedoneOpensea='https://opensea.io/collection/toward-hemelslinn-nft'
							piedoneYoutube='https://www.youtube.com/@TowardHemelslinnNFT'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="All rights reserved"
							piedoneCCBY='This template is built from a base of <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						/>




      				</div>
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}

    			</div>
			
			</>
		);
	}
}

export default TermsConditions;