import React, {Component} from 'react';
import VSLCarousel from './carosello';

class SectionProgetto extends Component {
    render() {
		const aCarosello = this.props.aCarosello;
        return (
            <section id="project" className="ftco-section bg-dark ftco-about-section">
                <div className="container-fluid ftco-animate">
                    <div className="row d-md-flex one-half text-wrapper">
						<VSLCarousel 
							aCarousel ={aCarosello}
							caroselloStyle={this.props.caroselloStyle}
						/>
                        <div className="one-half half-text d-flex justify-content-end align-items-center">
                            <div className="text-inner pl-md-5">
                                <h3 className="heading">{this.props.titoloProgetto}</h3>
                                <div className="CookieDeclaration" dir="ltr" lang="it" dangerouslySetInnerHTML={{ __html: this.props.descrizioneProgetto }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionProgetto;