import React, {Component} from 'react';

import Intestazione from './components_nft/intestazione';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';
import logothl from './images/Logo-THL-500xx500.png';

import intestazioneBackgroundImage from './images/toward-hemelslinn-sfondo-2.jpg';


const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class Mint extends Component {
	state = {
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
			{id: 1, linkHref: "https://mint.towardhemelslinn.io", linkNome: "MINT"},
			{id: 2, linkHref: "https://novel.towardhemelslinn.io", linkNome: "NOVEL"},
			{id: 3, linkHref: "https://galaxias.towardhemelslinn.io", linkNome: "GALAXIAS"},
			{id: 4, linkHref: "https://whitepaper.towardhemelslinn.io", linkNome: "WHITEPAPER"},
		],
	}
	render() {
		return (
			<>
				<div className="page">
					{/*SEZIONE BARRA DI NAVIGAZIONE */}
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='The Eliosfera publishing house logo'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>				
					<div id="pagina">
						<Intestazione
							pagina="SMART CONTRACT"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="TOWARD HEMELSLINN"
							romanzo="an NFT collection based on novel by"
							autore="MAURIZIO FERUGLIO"
						/>

						<section className="ftco-section bg-dark">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>SMART CONTRACT THL</h2>
											<p><b>Name:</b> Toward Hemelslinn NFT</p>
											<p><b>Symbol:</b> THL</p>
											<p><b>Blockchain:</b> Ethereum</p>
											<p><b>Token:</b> ERC-721A</p>
											<p><b>Mint:</b> Standard Mint, Whitelist Mint, Public Mint</p>
											<p><b>Registration date:</b> Jun-26-2023 11:43:35 PM +UTC</p>
											<p><b>Smart Contract hash:</b> 0x94f278e3A491A5b8E668e81d816AFa394c6d6647</p>
											<p>Toward Hemelslinn NFT Smart Contract: <a href="https://etherscan.io/address/0x94f278e3A491A5b8E668e81d816AFa394c6d6647"  target="_blank" rel="noreferrer noopener">https://etherscan.io/address/0x94f278e3A491A5b8E668e81d816AFa394c6d6647</a></p>
										</div>
									</div>
								</div>

								<div className="row justify-content-center">
									<img src={logothl} alt="Toward Hemelslinn NFT" style={{width: "60%"}} />
								</div>
							</div>
						</section>

           
						<Piedone
							piedoneLinkHowToMint='/howtomint'
							piedoneLinkNFTsProvenance='/nftsprovenance'
							piedoneLinkEbooksProvenance='/ebooksprovenance'
							piedoneLinkWhitePaper='https://whitepaper.towardhemelslinn.io'
							piedoneLinkTermConditions='/termsandconditions'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts='/smartcontracts'						
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/TowardHemelslin'
							piedoneInstagram=''
							piedoneFacebook=''
							piedoneDiscord='https://discord.gg/bWJ9nDthpA'
							piedonePinterest=''
							piedoneOpensea='https://opensea.io/collection/toward-hemelslinn-nft'
							piedoneYoutube='https://www.youtube.com/@TowardHemelslinnNFT'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="All rights reserved"
							piedoneCCBY='This template is built from a base of <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						/>




      				</div>
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}

    			</div>
			
			</>
		);
	}
}

export default Mint;