import React, {Component} from 'react';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';

export class SectionBooktrailerModal extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({isOpen: true});
    }
    render() {
        return (
            <section id={this.props.booktrailerModalId} className="ftco-section">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-5 heading-section text-center ftco-animate">
                            <h2 className="mb-4">{this.props.booktrailerModalTitolo}</h2>
                            <p className="mb-5">{this.props.booktrailerModalTagLine}</p>
                        </div>
                    </div>
                    <div className="row d-md-flex justify-content-center">       
                        <ModalVideo
                            channel="youtube"
                            youtube={{
                                autoplay: 1,
                                mute: 1,
								loop: 1,
                                width: "100%"
                            }}
                            
                            isOpen={this.state.isOpen}
                            videoId={this.props.booktrailerModalVideo}
                            onClose={() => this.setState({isOpen: false})}
                        />
                        <img src={this.props.booktrailerModalImage} alt={this.props.booktrailerModalAltImg}  onClick={this.openModal} style={{cursor: "Pointer", width: "80%"}} />
                    </div>
                </div>
            </section>
        );
    }
}
export default SectionBooktrailerModal;