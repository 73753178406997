import React, {Component} from 'react';
//import ReactPlayer from 'react-player'

import Intestazione from './components_nft/intestazione';
import Piedone from './components/piedone';
import Navbar from './components/navbar';

import logo from './images/Logo_Eliosfera-trasparente-scritta_nera-orizzontale-due-righe-v.3.1-70x280.png';

import intestazioneBackgroundImage from './images/toward-hemelslinn-sfondo-2.jpg';
import WhatsIsMetamask from './images/Banner-Whats-is-metamask.jpg';
import MetamaskBeginnersGuide from './images/Metamask-Beginners-guide.jpg';
const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};

// Create the function
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}


class HowTo extends Component {
	state = {
		aNavbarLinks: [
			{id: 0, linkHref: "/", linkNome: "HOME"},
			{id: 1, linkHref: "https://mint.towardhemelslinn.io", linkNome: "MINT"},
			{id: 2, linkHref: "https://novel.towardhemelslinn.io", linkNome: "NOVEL"},
            {id: 3, linkHref: "https://galaxias.towardhemelslinn.io", linkNome: "GALAXIAS"},
		],
	}
	render() {
		return (
			<>
				<div className="page">
					{/*SEZIONE BARRA DI NAVIGAZIONE */}
					<Navbar 
						linkSito='https://www.eliosfera.it'
						logo={logo}
						deskLogo='The Eliosfera publishing house logo'
						aNavbarLinks = {this.state.aNavbarLinks}
					/>
					<div id="pagina">
						<Intestazione
							pagina="HOW TO MINT"
							backgroundStyle={intestazioneBackgroundStyle}
							titolo="TOWARD HEMELSLINN"
							romanzo="an NFT collection based on novel by"
							autore="MAURIZIO FERUGLIO"
						/>

						<section className="ftco-section bg-dark">
							<div className="container">
								<div className="row justify-content-center">
									<div className="col-md-8 mb-5 heading-section text-center ftco-animate">
										<div className="heading-section text-center mb-5">
											<h2>HOW TO MINT</h2>
											<p>In this article we will provide you with detailed instructions on how to use MetaMask, create a Wallet and purchase our THL NFTs.</p>
										</div>
									</div>
								</div>

								<div className="row d-flex">
									<p>If you intend to enter the NFT sector you will necessarily have to configure your cryptographic wallet through which you can buy or sell cryptocurrencies and NFTs. Each wallet comes with a private key which gives you exclusive access to the contents of the wallet. Your wallet not only allows you to securely trade Bitcoin or Ethereum, but also trade any other cryptocurrency or NFT.</p>
									<p>There are several types of crypto wallets, including mobile apps and USB wallets. Most wallets work the same way, storing pairs of private keys that allow you to sync it across multiple devices to send and receive cryptocurrency or NFTs.</p>
									<p>Before buying an NFT or any currency it is imperative to know how to use and configure a cryptographic wallet.</p>
									<p>One of the most versatile crypto wallets is MetaMask. To coin our NFTs you need to use MetaMask. For this we recommend that you watch an informative video provided directly by the MetaMask producers.</p>
									<h2><strong>What is MetaMask?</strong></h2>

									<div className="row justify-content-center">
										<a href="https://www.youtube.com/watch?v=YVgfHZMFFFQ" target="_blank" rel="noreferrer noopener"><img src={WhatsIsMetamask} alt="Under Costruction" style={{width: "80%"}} /></a>
									</div>

									{/*<div className="row d-md-flex justify-content-center">       
										<div className="services ftco-animatex text-center">
											<div className="info mt-4">

												<ReactPlayer id="booktrailer" url="https://www.youtube.com/watch?v=YVgfHZMFFFQ"
													config={{
														youtube: {
															playerVars: {containment:'#home', showControls:true, autoPlay:true, loop:true, mute:false, startAt:0, opacity:1, quality:'default', width: '100%'}
														},
													}} 
												/>
											</div>
										</div>
									</div>*/}

									<p>To use MetaMask, you need to install the MetaMask extension using one of these browsers: Chrome, Brave, Firefox, or Edge. We suggest you follow the video guide below.</p>
									<h2><strong>MetaMask installation and configuration guide.</strong></h2>
								
									<div className="row justify-content-center">
										<a href="https://www.youtube.com/watch?v=MfkqgXNPiHg" target="_blank" rel="noreferrer noopener"><img src={MetamaskBeginnersGuide} alt="Under Costruction" style={{width: "80%"}} /></a>
									</div>
									{/*<div className="">       
										<div className="">
											<div className="">
												<ReactPlayer id="booktrailer" url="https://www.youtube.com/watch?v=MfkqgXNPiHg"
													config={{
														youtube: {
															playerVars: {containment:'#home', showControls:true, autoPlay:true, loop:true, mute:false, startAt:0, opacity:1, quality:'default', width: '100%'}
														},
													}}
												/>
											</div>
										</div>
									</div>*/}

									<h2><br /><strong>Buy ETH on MetaMask</strong></h2>
									<p>Buying ETH in MetaMask is very simple. On the main screen of the extension or mobile app, click or tap the "Buy" button. MetaMask will allow you to choose one of the available service providers through which you will be able to purchase Ethereum. Alternatively, you can transfer ETH from your other wallets or have someone send you ETH.</p>
									

									<h2><br /><strong>MINT an NFT Toward Hemelslinn (THL)</strong></h2>

									<p>Once MetaMask has been installed and the right amount of ETH has been transferred to the wallet, you can coin one or a Towards Shangri La NFT token in these ways:</p>
									<div>
										<ul>
											<li>To mint a THL NFT you will need to access the COIN page of our website.</li>
											<li>You will then need to link your MetaMask wallet to our site by clicking the CONNECT button.</li>
											<li>Once your wallet is connected, you can select how many THLs you want to coin and you can click on the MINT button.</li>
											<li>Once you have coined your THL NFTs, you will be able to check your METAMASK wallet for transaction details.</li>
											<li>By clicking on the VIEW ON OPENSEA button you can view your THL.</li>
										</ul>
										<p>Note: Any NFT minted will immediately appear in the clear on OpenSea. For more information visit our <a href="\#faq">FAQ</a>.</p>
									</div>
								</div>
							</div>
						</section>
						
           
						<Piedone
							piedoneLinkHowToMint='/howtomint'
							piedoneLinkNFTsProvenance='/nftsprovenance'
							piedoneLinkEbooksProvenance='/ebooksprovenance'
							piedoneLinkWhitePaper='https://whitepaper.towardhemelslinn.io'
							piedoneLinkTermConditions='/termsandconditions'
							piedoneLinkPrivacy='/privacy'
							piedoneLinkSmartContracts='/smartcontracts'
							piedoneIframe=''
							piedoneTwitter='https://twitter.com/TowardHemelslin'
							piedoneInstagram=''
							piedoneFacebook=''
							piedoneDiscord='https://discord.gg/bWJ9nDthpA'
							piedonePinterest=''
							piedoneOpensea='https://opensea.io/collection/toward-hemelslinn-nft'
							piedoneYoutube='https://www.youtube.com/@TowardHemelslinnNFT'
							piedoneCopyright="Eliosfera Editrice &copy;"
							piedoneCopyrightDisclaimer="All rights reserved"
							piedoneCCBY='This template is built from a base of <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
						/>




      				</div>
					{AddLibrary('js/jquery.min.js')}
					{AddLibrary('js/jquery-migrate-3.0.1.min.js')}
					{AddLibrary('js/jquery.waypoints.min.js')}
					{AddLibrary('js/jquery.stellar.min.js')}
					{AddLibrary('js/owl.carousel.min.js')}
					{AddLibrary('js/jquery.magnific-popup.min.js')}
					{AddLibrary('js/aos.js')}
					{AddLibrary('js/jquery.animateNumber.min.js')}
					{AddLibrary('js/scrollax.min.js')}
					{AddLibrary('js/jquery.mb.YTPlayer.min.js')}
					{AddLibrary('js/bootstrap-datepicker.js')}
					{AddLibrary('js/jquery.timepicker.min.js')}
					{AddLibrary('js/main.js')}

    			</div>
			
			</>
		);
	}
}

export default HowTo;